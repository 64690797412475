.tick.zero
  display none

.pkw-chart-mobile
  display none

.pkw-chart-desktop
  display block
  
@media screen and (max-width: screen-mobile-breakpoint)
  .pkw-chart-mobile
    display none
  .pkw-chart-desktop
    display block
