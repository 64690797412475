screen-mobile-breakpoint=768px
screen-tablet-breakpoint=996px


// colors
mjn-yellow = #FDE433
main-color = #000 // #7DCDB1
main-color-bright = #FDE433
second-color = #910CE8
second-color-bright = #CD0CE8
third-color = #550CE8
third-color-bright = #F97200

// fonts
@font-face 
  font-family 'Glyphicons Halflings';
  src url('../fonts/glyphicons-halflings-regular.eot');
  src url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg')
