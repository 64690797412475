@media screen and (min-width: screen-mobile-breakpoint)
  .app-view-mobile
    display none

page-layout-margins = 0 50px 0 50px
.moveLeft
  transition:0.3s linear all;
  transform: translateX(130px);

.moveOutLeft
  transition:0.3s linear all;
  transform: translateX(0px);

.menuDefault
  transform: translateX(130px);

.charts-view
.tables-view
.page-title
  // max-height: 50%
  display inline-block
  animation-fill-mode forwards

.charts-view
  width 90%
  left 30px
  height 400px
  // &.moveLeft
  // &.menuDefault
  //   transform: translateX(130px);

.tables-view
  left 55px
  position relative
  width 90%
  &.moveLeft
  &.menuDefault
    transform: translateX(170px);
    width 80%

.charts-view
.page-title
  margin page-layout-margins

.page-title
  margin-top 75px
  margin-bottom 25px
  margin-left 65px
  width 100%
  &.moveLeft
  &.menuDefault
    transform: translateX(160px);

.menu-btn
  height 100%
  display inline-block
  z-index: 1000
  font-size 20px
  border-radius 0
  border none
  background main-color
  color #FFF
  position relative
  &.active
  &:hover
  &:focus
    background second-color
    color #FFF
  &.open-menu
    width 50px
    line-height 35px
    padding-left 8px
  &.nav-btn
    width 150px
    text-align left
    padding-left 15px
    &.chart-btn
      width 125px
    &.stats-btn
      width 140px
    .glyphicon
      position absolute
      right 5px
      top 14px

h4.election-help-text
  position absolute
  right 280px
  color #FFF
  line-height 27px
  .glyphicon
    font-size 20px
    margin-left 10px

.election-dropdown
  right 10px
  width 260px
  text-align left
  position: absolute
  background-color mjn-yellow
  z-index: 1000
  color #000
  font-size 18px
  padding-top 10px
  &.active
    border-bottom 1px solid second-color-bright
  &.default
    text-align center
    font-weight 500
  span
    line-height 35px
    text-align right
    vertical-align middle
    &.glyphicon-chevron-down
      position absolute
      right 10px
      padding-top 5px

  &.kinds
    dropdown-height = 190px
    z-index: 999
    top (50 - dropdown-height)px
    background-color second-color
    display block
    height dropdown-height
    color #FFF
    text-align center

    &.moveDown
      transition 0.3s linear all;
      transform translateY(178px);
      // box-shadow -3px -5px 10px .5px #ccc
    &.moveOutDown
      transition 0.3s linear all;
      transform translateY(50);

    .element
      cursor pointer
      font-size 15px
      border-top 1px solid second-color-bright
      &:hover
        background-color second-color-bright
        color #FFF
      span
        padding-left 10px
.nav
  height 50px
  width 100%
  background-color main-color
  display block
  position fixed
  z-index 1000

.election-kinds
  height 30px
  background #DEDEDE
  position relative
  top 75px
  margin page-layout-margins


.menu
  height 100%
  width 200px
  display block
  position fixed
  top 50px
  left -200px
  z-index: 1000
  background second-color
  box-shadow 3px 5px 10px .5px #ccc
  color #FFF
  animation-duration 1s
  overflow-x auto
  &.moveLeft
  &.menuDefault
    transform: translateX(200px);

  input
    margin 10px 0 10px 10px
    width 180px
    border none
    padding-left 10px
    height 30px

.choose-election-data
  text-align center
  top 40%
  position absolute

.spinner
  position absolute
  z-index: 1000
  background-color rgba(255,255,255, 0.8)
  display block
  top 0
  bottom 0
  right 0
  left 0

@media screen and (max-width: 996px)
  .choose-election-data
    width 100%

.pkw-image-header
  position relative

  h1
    position: absolute;
    bottom: 20%
    left: 0;
    right: 0;
    text-align center;
    color: #FFF;
    font-size: 36px;
    margin 0
    text-shadow: 0 0px 9px rgb(0, 0, 0);

    @media screen and (min-width: 400px)
      text-shadow: 0px -4px 11px rgba(0,0,0, 0.8);
      font-size: 41px;

    @media screen and (min-width: 550px)
      font-size: 62px;

    @media screen and (min-width: 700px)
      font-size: 82px;

.pkw-header

  position relative
  padding 20px 0px

  &-image {
    width: 100%;
  }
  
  p
    font-size 1.15em
    @media screen and (min-width: 600px)
      font-size 1.4em
  
  &-share
    margin-top 20px
      
  &-share-item
    display inline-block
    height 1.5em
    vertical-align middle
    margin 0.5em

.pkw-chooser

  position relative
  background #eee;
  border-radius 3px
  padding 20px 10px
  margin-bottom 1em
  font-size 16px
  background-color #00173A;
  color #fff
  
  &-item
    display block
    line-height 40px
    
    @media screen and (min-width: 600px)
      display inline-block

  select
    padding 8px 2px
    border-radius 3px
    max-width 10em
    color #333
    margin-left 0.25em
    margin-right 1em
    
  &-button
    color #222
    border-radius 3px
    padding 8px 16px
    text-transform uppercase
    font-size 0.8em
    font-weight bold
    width 45%
    vertical-align middle
    line-height 1.4
    margin-left 0.5em
    margin-right 0.5em
    
    @media screen and (min-width: 600px)
      width auto
          
  &-button-show
    background #fde433
    border 1px solid #EED62C
    padding-left 25px
    padding-right 25px
    
    @media screen and (min-width: 600px)
      margin-left 2em
          
  &-button-reset
    background #fff
    border 1px solid #e3e3e3

  
.pkw-stats-links

  a
    cursor pointer
    margin 0 0.5em

.pkw-owner
  margin-bottom 2em
  
  &-logo 
    display block
    margin 0 auto
    width 200px
    max-width 100%
    margin-top 1em
    
    img
      width: 100%
    
.pkw-autor
  margin-bottom 2em

  &-photo
    border-radius 50%
    float left
    margin-right 15px
    width 80px

.pkw-sourcecode
  margin-bottom 2em
  
@-moz-keyframes three-quarters-loader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes three-quarters-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes three-quarters-loader {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* :not(:required) hides this rule from IE9 and below */
.three-quarters-loader:not(:required) {
  -moz-animation: three-quarters-loader 1250ms infinite linear;
  -webkit-animation: three-quarters-loader 1250ms infinite linear;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 8px solid #4C4C4C;
  border-right-color: transparent;
  border-radius: 16px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-indent: -9999px;
  width: 32px;
  height: 32px;
  position absolute
  top 50%
  margin-top -16px
}
